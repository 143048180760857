<template>
  <div id="app">
    <header class="header" v-if="isShowHeader">
      <div class="view-root">
        <div class="header-container">
          <div style="flex: 1;display: flex;align-items: center">
            <img src="./assets/logo-header.png" @click="goHome"
              style="width: 127px;height: 41px;object-fit: cover;margin-left: 32px;cursor: pointer">
            <p :class="checkRoutePath == 'ipsye-intro' ? 'item-tab-select' : 'item-tab-normal'"
              style="margin:  0px 20px 0px 160px;" @click="gotoTargetView('ipsye-intro')">About IPsyE</p>
            <p :class="checkRoutePath == 'ipsye-academy' ? 'item-tab-select' : 'item-tab-normal'"
              style="margin-right: 40px;margin-left: 20px;" @click="gotoTargetView('ipsye-academy')">IPsyO Academy</p>
            <p :class="checkRoutePath == 'case_list' ? 'item-tab-select' : 'item-tab-normal'"
              @click="gotoTargetView('case-list')">Virtual Therapy</p>
          </div>
          <div style="display: flex;flex-direction: row;align-items: center;">
            <div style="text-align: right;cursor:pointer;" @click="doCheckReceipt()">
              <p class="user-style" style="font-size: 18px;font-weight: 400">{{
                this.$UserData.user_name
              }}</p>
              <p class="user-style" style="margin-top: 6px;font-size: 14px;">{{ this.$UserData.account_name }}</p>
            </div>
            <img src="./assets/exit.png" @click="logout"
              style="width: 20px;height: auto;object-fit: cover;margin-left: 15px;margin-right: 32px;cursor: pointer;">
          </div>
        </div>
      </div>
    </header>
    <el-container style="margin: 0px auto;padding: 0px;">
      <el-main style="margin: 0px;padding: 0px">
        <router-view />
      </el-main>
    </el-container>
    <el-dialog :visible.sync="showPayDialog" width="450px" :modal="true" :modal-append-to-body="true">
      <div class="customDialogContent">
        <p style="text-align: center;font-size: 18px;color:#D60034;font-weight: bold;margin-top: -20px;">IPsyE
          Subscription Required</p>
        <p style="margin-top: 25px;font-size: 16px;line-height: 24px;color: #333;word-break: keep-all;">You haven't
          purchased the IPsyE subscription yet. Please purchase the subscription to access this content.</p>
        <div style="margin-top: 20px;display: flex;align-items: center;justify-content: center;">
          <p class="btn-cancel" @click="showPayDialog = false">Cancel</p>
          <p class="btn-purchase" @click="gotoCreateOrder">Purchase Now</p>
        </div>
      </div>
    </el-dialog>
    <Receipt :item="receiptInfo" v-if="showReceipt" @closeReceipt="showReceipt = false"></Receipt>
  </div>
</template>

<script>
import { clearUser, getAccountName, getUserId, getUserName, isTestUser } from './utils/store'
import './assets/common/common.css'
import { formatDate } from "./utils/date";
import { checkApplication, checkApplicationNew, createApplication, getPaymentApplication } from "./api/ipsye";
import Receipt from './components/receipt.vue'
import {Loading} from 'element-ui';
export default {
  components: { Receipt },
  name: 'App',
  data() {
    return {
      showReceipt: false,
      receiptInfo: {},
      userData: this.$UserData,
      subscription_content: 'Account Unpaid',
      showPayDialog: false,
      accountPaid: false
    }
  },
  watch: {
    userData: {
      handler(newVal, oldVal) {
        this.handleUserDataChanged();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$UserData.user_name = getUserName()
    this.$UserData.account_name = getAccountName()
    this.$UserData.user_id = getUserId()
    let that = this
    this.$globalMessage.$on("refreshApplication", (res) => {
      that.refreshApplication()
    });
    this.$globalMessage.$on("showPayDialog", (res) => {
      that.showPayDialog = true
    });
  },

  computed: {

    checkRoutePath() {
      let path = this.$route.path
      if (path.indexOf('case-list') >= 0) {
        return 'case_list'
      }
      if (path.indexOf('ipsye-intro') >= 0) {
        return 'ipsye-intro'
      }
      if (path.indexOf('ipsye-academy') >= 0) {
        return 'ipsye-academy'
      }
      return ''
    },

    isShowHeader() {
      // if (this.$route.path.search('pay') >= 0) {
      //   return false
      // }
      // if (this.$route.path.search('register') >= 0) {
      //   return false
      // }
      // if (this.$route.path.search('share') >= 0) {
      //   return false
      // }
      let path = this.$route.path
      let show = path.search('login') <= 0 && path.search('receipt') <= 0 && path.search('appForm') <= 0 && path.search('accountForm') <= 0 && path.search('pay') <= 0
      return show
    },
  },
  methods: {

    doCheckReceipt() {
      getPaymentApplication(getUserId()).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.receiptInfo = result.data
          this.showReceipt = true
        } else {
          this.showReceipt = false
        }
      })
    },

    gotoCreateOrder() {
      let user_id = getUserId()
      //
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
      });
      createApplication(user_id).then((res) => {
        let result = res.data.data
        if (result.application_status == '无需缴费') {
          this.accountPaid = true
          this.showPayDialog = false
          return
        }
        this.$router.push({
          path: '/order',
          query: {
            application_id: result._id.$id
          }
        })
        this.showPayDialog = false
      }).finally(() => {
        loadingInstance.close()
      })
    },

    gotoTargetView(type) {
      if (type == 'case-list') {
        if (isTestUser()) {
          if (this.accountPaid == false) {
            //dialog
            this.showPayDialog = true
            // return
          }
        }
        this.$router.push({ path: '/case-list' })
      }
      if (type == 'ipsye-intro') {
        if (isTestUser()) {
          if (this.accountPaid == false) {
            //dialog
            this.showPayDialog = true
            // return
          }
        }
        this.$router.push({ path: '/ipsye-intro' })
      }
      if (type == 'ipsye-academy') {
        if (isTestUser()) {
          if (this.accountPaid == false) {
            //dialog
            this.showPayDialog = true
            // return
          }
        }
        this.$router.push({ path: '/ipsye-academy' })
      }
    },

    handleUserDataChanged() {
      if (isTestUser()) {
        this.refreshApplication()
        return
      }
      let user_id = getUserId()
      checkApplication(user_id).then((res) => {
        let code = res.data.code
        if (code == 0) {
          let pay_time = res.data.data.application_payment_date_int
          let date = new Date(pay_time * 1000)
          let currentYear = date.getFullYear()
          let endTime = new Date(currentYear, 11, 31)
          let showEndTime = formatDate(new Date(endTime.getTime()), 'yyyy/MM/dd')
          this.subscription_content = 'Subscription Valid Until：' + showEndTime
        }
      })
    },
    refreshApplication() {
      let user_id = getUserId()
      if(!user_id){
        return
      }
      checkApplicationNew(user_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.accountPaid = true
        } else {
          this.accountPaid = false
        }
      })
    },

    goHome() {
      this.$router.push({
        path: '/'
      })
    },
    logout() {
      this.$alert('Are you sure you want to log out？', 'Notice', {
        confirmButtonText: 'YES',
        callback: action => {
          if (action == 'confirm') {
            clearUser()
            location.reload()
          }
        }
      })
    }
  }
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
</style>
<style scoped lang="less">
.btn-cancel {
  padding: 10px 0px;
  width: 160px;
  ;
  text-align: center;
  border: 1px solid #D60034;
  font-size: 16px;
  color: #D60034;
  cursor: pointer;
  border-radius: 5px;
}

.btn-purchase {
  margin-left: 20px;
  padding: 10px 0px;
  width: 160px;
  text-align: center;
  background-color: #D60034;
  font-size: 16px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}


.customDialogContent {
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.item-tab-normal {
  cursor: pointer;
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #999999;
  line-height: 10vh;
  height: 10vh;
}

.item-tab-select {
  .item-tab-normal();
  font-size: 24px;
  color: #D60034;
  font-weight: bold;
  border-style: solid;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px;
  border-bottom-color: #D60034;
}

.header-container {
  max-width: 1920px;
  margin: 0 auto;
  height: 10vh;
  background-color: white;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.user-style {
  font-size: 18px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: 500;
  color: #D60034;
}

#app {}
</style>
